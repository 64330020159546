import kaplay from "kaplay";
import "kaplay/global";

const k = kaplay(
    {
        buttons: {
            fire: {
                keyboard: ['enter', 'z'],
                mouse: ['left']
            },
            spell: {
                keyboard: ['shift', 'x'],
                mouse: ['right']
            },
            left: {
                keyboard: ['a', 'left']
            },
            right: {
                keyboard: ['d', 'right']
            },
            up: {
                keyboard: ['w', 'up']
            },
            down: {
                keyboard: ['s', 'down']
            }
        },
        width: 256,
        height: 240,
        scale: 3,
        background: [25,80,25]
    }
)

const CLASSES = {
    'wizard': {
        color: k.rgb(140,10,10),
        moveSpeed: 48,
        bulletSpeed: 96,
        fireCD: 0.5,
        spellCD: 3,
        hp: 3,
        weapon: 'wand',
        spell: 'teleport'
    },
    'paladin': {
        color: k.rgb(52,235,235),
        moveSpeed: 48,
        bulletSpeed: 192,
        fireCD: 0.5,
        spellCD: 5,
        hp: 5,
        weapon: 'sword',
        spell: 'consecration'
    },
    'rogue': {
        color: k.rgb(215,200,80),
        moveSpeed: 64,
        bulletSpeed: 192,
        fireCD: 0.1,
        spellCD: 1,
        hp: 4,
        weapon: 'dagger',
        spell: 'roll'
    }
}

const ENEMIES = {
    'bat': {
        color: k.rgb(0,0,255),
        moveSpeed: 84,
        damage: 1,
        width: 8,
        height: 8,
        spawnSpeed: 0.45
    },
    'walker': {
        color: k.rgb(0,0,192),
        moveSpeed: 48,
        damage: 2,
        width: 8,
        height: 16,
        spawnSpeed: 0.5
    }
}

k.scene('game', (cls) => {
    const MOVE_SPEED = CLASSES[cls].moveSpeed
    const BULLET_SPEED = CLASSES[cls].bulletSpeed
    const FIRE_CD = CLASSES[cls].fireCD
    const SPELL_CD = CLASSES[cls].spellCD
    const BASE_HP = CLASSES[cls].hp
    const WEAPON = CLASSES[cls].weapon
    const SPELL = CLASSES[cls].spell

    const player = k.add([
        k.rect(8,16),
        k.pos(k.width()/2-4, k.height()/2-8),
        k.area(8,16),
        k.color(CLASSES[cls].color),
        k.timer(),
        k.outline(1, rgb(0,0,0)),
        k.z(10),
        k.animate(),
        'player',
        {
            direction: {
                x: 1,
                y: 0
            },
            aiming: {
                x: 0,
                y: 0
            },
            buttons: {
                left: 0,
                right: 0,
                up: 0,
                down: 0
            },
            canFire: true,
            canSpell: true,
            invulnerable: false,
            inMovementEffect: false,
            hp: BASE_HP
        }
    ])

    const fireCD = k.add([
        k.rect(8,8),
        k.pos(24,8),
        k.color(255,255,255),
        k.timer(),
        k.outline(1, rgb(0,0,0))
    ])

    const spellCD = k.add([
        k.rect(8,8),
        k.pos(48,8),
        k.color(255,255,255),
        k.timer(),
        k.outline(1, rgb(0,0,0))
    ])

    const hp = k.add([
        k.text(
            "HP: " + BASE_HP,
            {size: 10}
        ),
        k.pos(48*2, 8)
    ])

    const kills = k.add([
        k.text(
            "KO: 0",
            {size: 10}
        ),
        k.pos(48*2+48, 8),
        {kills: 0}
    ])

    const timer = k.add([
        k.text(
            "5:00",
            {size: 10, align: 'right'}
        ),
        k.pos(k.width()-48, 8),
        { secs: 60*5 },
        k.timer()
    ])

    timer.loop(1, () => {
        timer.secs -= 1
        let mins = Math.floor(timer.secs / 60)
        let secs = timer.secs - mins*60
        let dispSecs = secs < 10 ? `0${secs}` : secs
        timer.text = `${mins}:${dispSecs}`
    })

    k.onButtonPress('fire', () => {
        if ( player.inMovementEffect ) return;

        if ( player.canFire ) {
            spawnBullet(
                WEAPON, 
                player.pos, 
                k.vec2(player.direction.x, player.direction.y)
            )
            player.canFire = false

            fireCD.tween(rgb(0,0,0), rgb(255,255,255), FIRE_CD, (p) => fireCD.color = p, easings.linear)
            player.wait(FIRE_CD, () => {
                player.canFire = true
            })
        }
    })
    k.onButtonPress('spell', () => {
        if ( player.inMovementEffect ) return;

        if ( player.canSpell ) {
            castSpell(SPELL)
        }
        
    })
    k.onButtonDown(['left','right','up','down'], (btn) => {
        if ( player.inMovementEffect ) return;

        if ( btn == 'left') {
            player.buttons.left = 1
            player.direction.x = -1
            if ( player.buttons.up == 0 && player.buttons.down == 0 ) {
                player.direction.y = 0
            }
        }
        if ( btn == 'right') {
            player.buttons.right = 1
            player.direction.x = 1
            if ( player.buttons.up == 0 && player.buttons.down == 0 ) {
                player.direction.y = 0
            }
        }
        if ( btn == 'up') {
            player.buttons.up = 1
            player.direction.y = -1
            if ( player.buttons.left == 0 && player.buttons.right == 0 ) {
                player.direction.x = 0
            }
        }
        if ( btn == 'down') {
            player.buttons.down = 1
            player.direction.y = 1
            if ( player.buttons.left == 0 && player.buttons.right == 0 ) {
                player.direction.x = 0
            }
        }
    })
    k.onButtonRelease(['left','right','up','down'], (btn) => {
        // if ( player.inMovementEffect ) return;

        if ( btn == 'left') {
            player.buttons.left = 0
            if ( player.buttons.right == 0 ) {
                player.direction.x = 0
            }
            if ( player.buttons.up == 0 && player.buttons.down == 0 ) {
                player.direction.y = 0
            }
        }
        if ( btn == 'right') {
            player.buttons.right = 0
            if ( player.buttons.left == 0 ) {
                player.direction.x = 0
            }
            if ( player.buttons.up == 0 && player.buttons.down == 0 ) {
                player.direction.y = 0
            }
        }
        if ( btn == 'up') {
            player.buttons.up = 0
            if ( player.buttons.down == 0 ) {
                player.direction.y = 0
            }
            if ( player.buttons.left == 0 && player.buttons.right == 0 ) {
                player.direction.x = 0
            }
        }
        if ( btn == 'down') {
            player.buttons.down = 0
            if ( player.buttons.up == 0 ) {
                player.direction.y = 0
            }
            if ( player.buttons.left == 0 && player.buttons.right == 0 ) {
                player.direction.x = 0
            }
        }
        if ( player.direction.x == 0 && player.direction.y == 0 ) {
            player.direction.x = (btn == 'left' ? -1 : 0) + (btn == 'right' ? 1 : 0)
            player.direction.y = (btn == 'up' ? -1 : 0) + (btn == 'down' ? 1 : 0)
        }
    })
    k.onUpdate('player', (player) => {
        let moveX = (player.buttons.left * -1) + (player.buttons.right);
        let moveY = (player.buttons.up * -1) + (player.buttons.down);
        
        if ( player.inMovementEffect ) {
            moveX = player.direction.x
            moveY = player.direction.y
        }

        // If both x and y are not zero, normalize the vector
        const magnitude = Math.sqrt(moveX * moveX + moveY * moveY);
        
        if (magnitude > 0) {
            moveX = (moveX / magnitude) * MOVE_SPEED;
            moveY = (moveY / magnitude) * MOVE_SPEED;
        }
        if ( player.inMovementEffect ) {
            moveX = moveX * 2
            moveY = moveY * 2
        }
    
        player.move(moveX, moveY);

        if ( player.pos.x > k.width() - player.width ) {
            player.pos.x = k.width() - player.width
        }
        if ( player.pos.x < 0 ) {
            player.pos.x = 0
        }
        if ( player.pos.y > k.height() - player.height ) {
            player.pos.y = k.height() - player.height
        }
        if ( player.pos.y < 0 ) {
            player.pos.y = 0
        }
    })

    function spawnBullet(weapon, pos, dir) {
        if ( weapon == 'wand' ) {
            k.add([
                k.rect(8,8),
                k.area(),
                k.pos(pos),
                k.color(255,128,0),
                k.move(dir, BULLET_SPEED),
                k.offscreen({destroy: true}),
                k.outline(1, rgb(0,0,0)),
                'bullet'
            ])
        }

        if ( weapon == 'sword' || weapon == 'dagger' ) {
            let weaponPos = pos
            let weaponDirY = 1
            let weaponLength = 16
            let weaponHeight = 2

            if ( weapon == 'sword' ) {
                weaponLength = 24
                weaponHeight = 4
            }

            if ( dir.x != 0 ) {
                weaponPos = dir.x < 0 ? vec2(pos.x - weaponLength, pos.y) : vec2(pos.x + 8, pos.y)
                weaponDirY = dir.y < 0 ? -1 : 1
            }
            if ( dir.x == 0 ) {
                weaponPos = dir.y < 0 ? vec2(pos.x, pos.y) : vec2(pos.x, pos.y+16)
                weaponDirY = dir.y < 0 ? -1 : 1
            }


            // debug.log(`dir: ${dir.x} ${dir.y}`)
            const sword = k.add([
                k.rect(weaponLength, weaponHeight),
                k.area(),
                k.pos(weaponPos),
                k.color(192,192,192),
                k.move(vec2(0,weaponDirY), BULLET_SPEED),
                k.offscreen({destroy: true}),
                k.outline(1, rgb(0,0,0)),
                k.timer(),
                'bullet'
            ])
            sword.wait(0.1, () => {
                sword.destroy()
            })
        }
    }

    function castSpell(spell) {
        if ( spell == 'teleport' ) {
            let newX = k.rand(0,1) * k.width()
            let newY = k.rand(0,1) * k.height()

            player.invulnerable = true
            player.moveTo(vec2(newX,newY))
            const explosion = k.add([
                k.pos(newX,newY),
                k.circle(16),
                k.area(),
                k.color(255,128,0),
                k.timer(),
                k.z(0),
                k.anchor('center'),
                'spell'
            ])
            
            player.canSpell = false

            spellCD.tween(rgb(0,0,0), rgb(255,255,255), SPELL_CD, (p) => spellCD.color = p, easings.linear)
            explosion.tween(rgb(255,128,0), rgb(255,255,255), 0.5, (p) => explosion.color = p, easings.easeInCubic)
            player.wait(SPELL_CD, () => {
                player.canSpell = true
            })
            explosion.wait(0.5, () => {
                explosion.destroy()
                player.invulnerable = false
            })

            return;
        }

        if ( spell == 'consecration' ) {
            player.invulnerable = true
            
            const explosion = k.add([
                k.pos(player.pos.x,player.pos.y),
                k.circle(32),
                k.area(),
                k.color(255,128,0),
                k.timer(),
                k.z(0),
                k.anchor('center'),
                'spell'
            ])
            
            player.canSpell = false

            spellCD.tween(rgb(0,0,0), rgb(255,255,255), SPELL_CD, (p) => spellCD.color = p, easings.linear)
            explosion.tween(rgb(255,128,0), rgb(255,255,255), 0.5, (p) => explosion.color = p, easings.easeInCubic)
            player.wait(SPELL_CD, () => {
                player.canSpell = true
            })
            explosion.wait(0.5, () => {
                explosion.destroy()
                player.invulnerable = false
            })
        }

        if ( spell == 'roll' ) {
            player.invulnerable = true
            player.inMovementEffect = true
            player.canSpell = false

            spellCD.tween(rgb(0,0,0), rgb(255,255,255), SPELL_CD+0.5, (p) => spellCD.color = p, easings.linear)
            player.tween(CLASSES[cls].color, k.rgb(0,0,0), 0.25, (p) => player.color = p, easings.easeInBounce)
            player.tween(0, 360, 0.5, (val) => player.angle = val, easings.linear)
            player.wait(0.25, () => {
                player.tween(k.rgb(0,0,0), CLASSES[cls].color, 0.25, (p) => player.color = p, easings.easeInBounce)
            })
            player.wait(0.5, () => {
                player.invulnerable = false
                player.inMovementEffect = false
            })
            player.wait(SPELL_CD + 0.5, () => {
                player.canSpell = true
            })
        }
    }

    function spawnBat() {  
        let sideSpan = k.randi(0,4);     
        let xPos = 0
        let yPos = 0

        /*
            0 ->
            90 \/
            180 <-
            270 /\
        */

        switch ( sideSpan ) {
            case 0: //top
                xPos = k.rand(0,2) * k.width()
                yPos = 0
                break;
            case 1: //right
                xPos = k.width()
                yPos = k.rand(0,2) * k.height()
                break;
            case 2: //bottom
                xPos = k.rand(0,2) * k.width()
                yPos = k.height()
                break;
            case 3: //left
                xPos = 0
                yPos = k.rand(0,2) * k.height()
                break;
        }
        
        k.add([
            k.rect(ENEMIES.bat.width,ENEMIES.bat.height),
            k.area(),
            k.pos(xPos,yPos),
            k.color(ENEMIES.bat.color),
            k.move(player.pos.angle(vec2(xPos,yPos)), ENEMIES.bat.moveSpeed),
            k.offscreen({destroy:true}),
            k.outline(1, rgb(0,0,0)),
            'enemy'
        ])
        

        wait(ENEMIES.bat.spawnSpeed, spawnBat)
    }

    function spawnWalker() {  
        let sideSpan = k.randi(0,4);     
        let xPos = 0
        let yPos = 0

        let enemyDir = Vec2.fromAngle(90)

        /*
            0 ->
            90 \/
            180 <-
            270 /\
        */

        switch ( sideSpan ) {
            case 0: //top
                xPos = k.rand(0,2) * k.width()
                yPos = 0
                enemyDir = Vec2.fromAngle(90)
                break;
            case 1: //right
                xPos = k.width()
                yPos = k.rand(0,2) * k.height()
                enemyDir = Vec2.fromAngle(180)
                break;
            case 2: //bottom
                xPos = k.rand(0,2) * k.width()
                yPos = k.height()
                enemyDir = Vec2.fromAngle(270)
                break;
            case 3: //left
                xPos = 0
                yPos = k.rand(0,2) * k.height()
                enemyDir = Vec2.fromAngle(0)
                break;
        }
        
        k.add([
            k.rect(ENEMIES.walker.width, ENEMIES.walker.height),
            k.area(),
            k.pos(xPos,yPos),
            k.color(ENEMIES.walker.color),
            k.move(enemyDir, ENEMIES.walker.moveSpeed),
            k.offscreen({destroy:true}),
            k.outline(1, rgb(0,0,0)),
            'enemy'
        ])
        

        wait(ENEMIES.walker.spawnSpeed, spawnWalker)
    }

    player.onCollide('enemy', () => {
        if ( player.invulnerable ) {
            return
        }
        
        k.shake(2)
        player.hp = player.hp - 1
        hp.text = `HP: ${player.hp}`
        player.invulnerable = true
        player.tween(CLASSES[cls].color,rgb(255,255,255),0.25, (c) => player.color = c, easings.easeInOutCubic)
        player.wait(0.25, () => {
            player.tween(rgb(255,255,255),CLASSES[cls].color,0.25, (c) => player.color = c, easings.easeInOutCubic)
        })
        player.wait(0.5, () => {
            player.invulnerable = false
        })

        if ( player.hp <= 0 ) {
            k.go('gameOver', timer.secs, kills.kills)
        }
    })

    k.onCollide('bullet', 'enemy', (bullet, enemy) => {
        kills.kills = kills.kills + 1
        kills.text = `KO: ${kills.kills}`
        enemy.destroy()
        if ( WEAPON == 'wand' ) {
            bullet.destroy()
        }
    })

    k.onCollide('spell', 'enemy', (spell, enemy) => {
        kills.kills = kills.kills + 1
        kills.text = `KO: ${kills.kills}`
        enemy.destroy()
    })

    k.randSeed(Date.now())
    spawnBat()
    spawnWalker()
})

k.scene('title', () => {
    const classes = []
    k.add([
        k.text('da wizzler', {
            size: 24, 
            width: k.width(), 
            align: 'center'
        }),
        k.pos(0,60),
        k.color(0,0,0)
    ])
    k.add([
        k.text('weapon/action = z/enter/left click', {
            size:10,
            width: k.width(),
            align: 'center'
        }),
        k.pos(0,180),
        k.color(0,0,0)
    ])
    k.add([
        k.text('spell = x/shift/right click', {
            size:10,
            width: k.width(),
            align: 'center'
        }),
        k.pos(0,196),
        k.color(0,0,0)
    ])
    k.add([
        k.text('move/select = wasd/arrows', {
            size:10,
            width: k.width(),
            align: 'center'
        }),
        k.pos(0,212),
        k.color(0,0,0)
    ])
    classes[0] = k.add([
        k.text('wizard', {
            size: 12,
        }),
        k.pos(80, 120),
        k.color(255,255,255),
        {class: 'wizard'}
    ])
    classes[1] = k.add([
        k.text('paladin', {
            size: 12,
        }),
        k.pos(80, 136),
        k.color(0,0,0),
        {class: 'paladin'}
    ])
    classes[2] = k.add([
        k.text('rogue', {
            size: 12,
        }),
        k.pos(80, 152),
        k.color(0,0,0),
        {class: 'rogue'}
    ])
    const cursor = k.add([
        k.pos(64,120),
        k.rect(8,8),
        k.color(255,255,255),
        {selected: 0}
    ])

    k.onButtonPress('up', () => {
        cursor.selected = cursor.selected == 0 ? 2 : cursor.selected - 1
        updateCursor()
    })
    k.onButtonPress('down', () => {
        cursor.selected = cursor.selected == 2 ? 0 : cursor.selected + 1
        updateCursor()
    })

    k.onButtonPress('fire', () => {
        k.go('game', classes[cursor.selected].class)
    })

    function updateCursor() {
        for ( let i = 0; i < classes.length; i++ ) {
            classes[i].color = k.rgb(0,0,0)
            if ( i == cursor.selected ) {
                classes[i].color = k.rgb(255,255,255)
                cursor.moveTo(64, classes[i].pos.y)
            }
        }
    }
})

k.scene('gameOver', (time, kills) => {
    k.add([
        k.text(`KOs: ${kills}`, {size: 24, width: k.width(), align: 'center'}),
        k.pos(0,60),
        k.color(0,0,0)
    ])

    time = 60*5 - time
    let mins = Math.floor(time/60)
    let secs = time - mins*60
    let dispSecs = secs < 10 ? `0${secs}` : secs
    k.add([
        k.text(`Survived: ${mins}:${dispSecs}`, {size: 24, width: k.width(), align: 'center'}),
        k.pos(0,108),
        k.color(0,0,0)
    ])

    k.add([
        k.text('press fire to restart', {size: 24, width: k.width(), align: 'center'}),
        k.pos(0,156),
        k.color(0,0,0)
    ])

    k.onButtonPress('fire', () => {
        k.go('title')
    })
})

k.go('title')